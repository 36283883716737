import React, { useState, useEffect } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import EdeptoCalculatorDetails from "./EdeptoCalculatorDetails";

//https://react-component.github.io/slider/?path=/story/rc-slider--handle
//https://github.com/react-component/slider

function EdeptoCalculator() {
  const [salaryVariables, setSalaryVariables] = useState({
    hourlyRate: 800,
    weeksOfVacation: 6,
    workload: 100,
    occupationalPension: 5,
    healthcare: 5000,
    privateHealthcarePlan: 6000,
    conferences: 15000,
    representation: 9000,
  });

  const [salary, setSalary] = useState({
    edeptoSalary: 0,
    castraSalary: 0,
    visioniteSalary: 0,
  });

  var calculateSalaryforAll = () => {
    var newSal = {
      edeptoSalary: calculateSalary(edeptoCut),
      castraSalary: calculateSalary(castraCut),
      visioniteSalary: calculateSalary(visioniteCut),
    };
    setSalary(newSal);
  };

  var calculateSalary = (cut) => {
    var workload = salaryVariables.workload / 100;
    var occupationalPension = salaryVariables.occupationalPension / 100;

    // calcs
    var personalHourlyRate = salaryVariables.hourlyRate * cut;
    var hoursOfVacation = salaryVariables.weeksOfVacation * 40;
    var expectedWorkPerYear = (hoursPerYear - hoursOfVacation) * workload;
    var annualRevenue = personalHourlyRate * expectedWorkPerYear;

    // remove const costs
    var revenueAfterConstCosts =
      annualRevenue -
      salaryVariables.representation -
      salaryVariables.healthcare -
      salaryVariables.conferences -
      salaryVariables.privateHealthcarePlan -
      administrativePrograms -
      liabilityInsurance;

    var occupionalPensionTotalPercentage =
      (occupationalPension * (1 + taxOccupationalPension)) / (1 + arbAvg);

    var toSalary =
      revenueAfterConstCosts / (1 + occupionalPensionTotalPercentage);
    var annualSalaryBrutto = toSalary / (1 + arbAvg);
    var annualOccupationalPension = annualSalaryBrutto * occupationalPension;
    var monthlySalaryBrutto = annualSalaryBrutto / monthsPerYear;
    var annualArbAvg = annualSalaryBrutto * arbAvg;
    var annualOccupationalPensionCost =
      annualSalaryBrutto * occupationalPension * (1 + taxOccupationalPension);

    var salary = {
      annualSalaryBrutto: Math.round(annualSalaryBrutto),
      totalannualOccupationalPensionScore: annualOccupationalPension,
      monthlySalaryBrutto: Math.round(monthlySalaryBrutto),
      annualArbAvg: Math.round(annualArbAvg),
      annualOccupationalPensionCost: Math.round(annualOccupationalPensionCost),
      annualRevenue: Math.round(annualRevenue),
    };

    /*console.log(
      "Calculating salary with hourlyRate=" +
        salaryVariables.hourlyRate +
        ", weeksOfVacation=" +
        salaryVariables.weeksOfVacation +
        ", workload=" +
        workload +
        ", cut=" +
        cut +
        ", result=>" +
        JSON.stringify(salary)
    );*/

    return salary;
  };

  useEffect(() => {
    //console.log(salaryVariables, "- Has changed");
    calculateSalaryforAll();
  }, [salaryVariables]);

  return (
    <>
      <article id="salcalc">
        <h2 className="major">Lönekalkylator</h2>
        <blockquote>
          Edepto handlar om att ge tillbaka så mycket som möjligt av intäkterna
          (90 %) till konsulten samtidigt som företaget ska vara välmående. Här
          kan du själv testa hur din kompensation skulle kunna se ut som
          anställd på Edepto.
        </blockquote>
        <div>
          <label>Arvode: {salaryVariables.hourlyRate} SEK/h</label>
          <Slider
            style={{ marginBottom: 40 }}
            min={600}
            max={1200}
            defaultValue={800}
            step={10}
            onChange={(d) => {
              setSalaryVariables({ ...salaryVariables, hourlyRate: d });
            }}
          />
        </div>
        <div>
          <label>Semester: {salaryVariables.weeksOfVacation} veckor</label>

          <Slider
            style={{ marginBottom: 40 }}
            min={5}
            max={15}
            defaultValue={6}
            step={1}
            onChange={(d) => {
              setSalaryVariables({ ...salaryVariables, weeksOfVacation: d });
            }}
          />
        </div>
        <div>
          <label>Arbetstid: {salaryVariables.workload} %</label>
          <Slider
            style={{ marginBottom: 40 }}
            min={50}
            max={100}
            defaultValue={100}
            step={5}
            onChange={(d) => {
              setSalaryVariables({ ...salaryVariables, workload: d });
            }}
          />
        </div>

        <div style={{ border: "1px solid white", padding: "20px" }}>
          <h4 style={{ margin: 0 }}>
            Månadslön: {formSEK(salary.edeptoSalary.monthlySalaryBrutto)} SEK
          </h4>
        </div>
        <div style={{ marginTop: 30 }}>
          Motsvarande månadslön hos konkurrent med 70 % samt 80 % fördelning:
        </div>
        <div>
          Bolag C****a: {formSEK(salary.castraSalary.monthlySalaryBrutto)} (
          <span style={{ color: "red" }}>
            {" "}
            -
            {formSEK(
              salary.edeptoSalary.monthlySalaryBrutto -
                salary.castraSalary.monthlySalaryBrutto
            )}
          </span>
          ) SEK
        </div>
        <div>
          Bolag V*******e: {formSEK(salary.visioniteSalary.monthlySalaryBrutto)}{" "}
          (
          <span style={{ color: "red" }}>
            {" "}
            -
            {formSEK(
              salary.edeptoSalary.monthlySalaryBrutto -
                salary.visioniteSalary.monthlySalaryBrutto
            )}
          </span>
          ) SEK
        </div>
        <EdeptoCalculatorDetails
          salary={salary}
          salaryVariables={salaryVariables}
          setSalaryVariables={setSalaryVariables}
        />
      </article>
    </>
  );
}

function formSEK(value) {
  if (value === 0) {
    return 0;
  }
  if (value) {
    return value.toLocaleString("se");
  }
}

export default EdeptoCalculator;

var edeptoCut = 0.9;
var visioniteCut = 0.8;
var castraCut = 0.7;

var hoursPerYear = 2004;

var monthsPerYear = 12;

var administrativePrograms = 1200;
var liabilityInsurance = 2400;

var arbAvg = 0.3142;
var taxOccupationalPension = 0.2426;
