function Policy() {
  return (
    <article id="riktlinjer_policydokument">
      <h2 className="major">Riktlinjer/policydokument</h2>
      <p className="less-margin">
      För att bli den bästa affärspartnern och arbetsgivaren krävs en tydlig vision och vägledning. Det är därför vi på Edepto AB har ett verksamhetsledningsystem baserat på <a href="https://www.fr2000.se" target="_blank">FR2000</a>. Tackvare det har vi etablerat ett ramverk av interna regelverk och policydokument. Dessa, i kombination med relevanta externa regler, ger oss en stabil grund för att driva vår verksamhet på ett framgångsrikt och ansvarsfullt sätt.
      </p>
      
      <h3>
        <a name="arbetsmiljöpolicy"></a>Arbetsmiljöpolicy
      </h3>
      <p style={{ margin: "0 0 0 0" }}>
      Hos Edepto  strävar vi efter att skapa en arbetsmiljö som främjar både fysisk och psykisk hälsa. Vi anser att ingen medarbetare ska behöva drabbas av ohälsa eller skada till följd av sitt arbete. Skulle en sådan situation ändå uppstå har Edepto som arbetsgivare väl förberedda rutiner för att hantera den på bästa sätt. Vi ger stöd och hjälp till den drabbade medarbetaren och ser till att nödvändiga åtgärder vidtas för att förhindra att liknande situationer uppstår i framtiden.
      </p>
      <p><a href="/policies/amp_1.0.pdf" target="_blank"><b>Arbetsmiljöpolicy (pdf)</b></a></p>
      <h3>
        <a name="integritetspolicy"></a>Integritetspolicy
      </h3>
      <p style={{ margin: "0 0 0 0" }}>
      I vår integritetspolicy förklarar vi tydligt hur vi samlar in, använder och delar dina personuppgifter inom ramen för vår verksamhet. Vi beskriver också dina rättigheter och hur du kan kontakta oss om du har frågor.
      </p>
      <p><a href="/policies/ip_1.0.pdf" target="_blank"><b>Integritetspolicy (pdf)</b></a></p>
      <h3>
        <a name="kvalitetspolicy"></a>Kvalitetspolicy
      </h3>
      <p style={{ margin: "0 0 0 0" }}>
      Hos Edepto är vi fast beslutna att leverera tjänster, lösningar och system av högsta kvalitet som överträffar våra kunders förväntningar och förtjänar deras fulla tillfredsställelse. Vi strävar efter att bli den självklara partnern för våra kunder genom att erbjuda värdeskapande och effektiva lösningar på deras problem.
      </p>
      <p><a href="/policies/kp_1.0.pdf" target="_blank"><b>Kvalitetspolicy (pdf)</b></a></p>
      <h3>
        <a name="miljöpolicy"></a>Miljöpolicy
      </h3>
      <p style={{ margin: "0 0 0 0" }}>
      Med utgångspunkt i vår vision om ett hållbart och humant samhälle genom digitalisering och innovation, strävar vi efter att vara en positiv kraft i miljö- och hållbarhetsfrågor. Vi verkar för att minimera vår negativa miljöpåverkan och arbeta etiskt samt visa respekt för våra anställda och andra företag i vår värdekedja.      
      </p>
      <p><a href="/policies/mp_1.0.pdf" target="_blank"><b>Miljöpolicy (pdf)</b></a></p>
      <h3>
        <a name="Uppförandekod"></a>Uppförandekod
      </h3>
      <p style={{ margin: "0 0 0 0" }}>
      Hos Edepto AB genomsyrar en stark företagskultur allt vi gör. Den grundar sig i en djup respekt för människan och vår planet, och präglas av ansvarsfulla ledare, engagerade medarbetare och en öppen och ärlig kommunikation. Denna kultur är inte bara ord på papper, utan återspeglas i våra handlingar och i hur vi interagerar med våra olika intressenter.
      </p>
      <p><a href="/policies/uk_1.0.pdf" target="_blank"><b>Uppförandekod (pdf)</b></a></p>
    </article>
  );
}

export default Policy;
