function Contact() {
  return (
    <article id="contact">
      <h2 className="major">Kontakt</h2>
      <form method="post" action="#">
        <div className="fields">
          <div className="field half">
            <div>Hannes Bjurek</div>
            <div>hannes@edepto.se</div>
            <div>0760-213721</div>
          </div>
          <div className="field half">
            <div>Mikael Bäckström</div>
            <div>mikael@edepto.se</div>
            <div>0760-214243</div>
          </div>
        </div>
      </form>
      <ul className="icons">
        <li>
          <a
            href="https://www.linkedin.com/company/edepto-ab/"
            className="icon brands fa-linkedin"
          >
            <span className="label">LinkedIn</span>
          </a>
        </li>
      </ul>
    </article>
  );
}

export default Contact;
