function AboutUs() {
  return (
    <article id="about">
      <h2 className="major">Om oss</h2>
      <span className="image main">
        <img src="images/pic03.jpg" alt="" />
      </span>
      <p>
        Hannes Bjurek och Mikael Bäckström, som är grundarna av Edepto, har
        delat en stark vänskap och professionell resa sedan de först möttes
        under grundskolans tidiga dagar på 1980-talet. Deras gemensamma väg tog
        en avgörande vändning i början av 2000-talet när de både valde att
        engagera sig i IT-sektorn. Från 2010 till 2021 hade de framgångsrikt
        drivit ett IT-konsultföretag, där de ackumulerade omfattande kunskap och
        erfarenhet som nu utgör grunden för Edepto. Deras ihärdiga engagemang
        för att sammanföra talangfulla konsulter med utmanande uppdrag och
        säkerställa högkvalitativa leveranser brinner lika starkt nu som vid
        deras karriärers början, nästan två decennier senare.
      </p>
      <p>
        Tillsammans innehar Hannes och Mikael över fyrtio års kombinerad
        expertis inom IT-konsultbranschen, där de har innehaft roller som
        sträcker sig från att vara konsulter själva till att anställa konsulter
        för uppdragsarbete, samt att utveckla och implementera nya
        affärsstrategier. Denna breda erfarenhetsbas är en vital tillgång för
        Edepto, där de fortsätter att bygga en verksamhet som är lika dedikerad
        till sina klienters framgång som till det professionella växandet hos
        sina konsulter.
      </p>
    </article>
  );
}

export default AboutUs;
