import React, { useState } from "react";
import DetailedLabel from "./DetailedLabel";

import AnimateHeight from "react-animate-height";
import "rc-slider/assets/index.css";
function DetailedRow(props) {
  const [expanded, setExpanded] = useState(false);
  return (
    <tr>
      <td>
        <DetailedLabel
          expandable={!!props.children}
          setExpanded={setExpanded}
          expanded={expanded}
          title={props.title}
        />

        <AnimateHeight duration={500} height={expanded ? "auto" : 0}>
          <div>{props.children}</div>
        </AnimateHeight>
      </td>

      <td style={{ textAlign: "right" }}>{formSEK(props.cost)}</td>
    </tr>
  );
}

function formSEK(value) {
  if (value === 0) {
    return 0;
  }
  if (value) {
    return value.toLocaleString("se");
  }
}
export default DetailedRow;
