import React, { useState } from "react";
import Switch from "react-switch";
import Slider from "rc-slider";
import AnimateHeight from "react-animate-height";
import "rc-slider/assets/index.css";

import DetailedRow from "./DetailedRow";

//https://www.npmjs.com/package/react-switch
//https://github.com/Stanko/react-animate-height

function EdeptoCalculatorDetails({
  salary,
  salaryVariables,
  setSalaryVariables,
}) {
  const [detailsHeight, setDetailsHeight] = useState(0);

  const [privateHealthcarePlanChecked, setPrivateHealthcarePlanChecked] =
    useState(true);

  const toggleDetails = (e) => {
    e.preventDefault();
    setDetailsHeight(detailsHeight === 0 ? "auto" : 0);
    fetch("/calcdummydetails");
  };

  return (
    <>
      <label onClick={toggleDetails} style={{ marginTop: 30 }}>
        {detailsHeight === 0 && (
          <span>
            Se detaljer <i className="fas fa-chevron-down"></i>
          </span>
        )}
        {detailsHeight !== 0 && (
          <span>
            Dölj detaljer <i className="fas fa-chevron-up"></i>
          </span>
        )}
      </label>
      <AnimateHeight
        duration={500}
        height={detailsHeight} // see props documentation below
      >
        <>
          <p>
            Årsintäkt{" "}
            <span style={{ fontWeight: "bold" }}>
              {formSEK(salary.edeptoSalary.annualRevenue)} SEK{" "}
            </span>
            som fördelas enligt följande:
          </p>
          <div className="table-wrapper" style={{ marginTop: 30 }}>
            <table>
              <thead>
                <tr>
                  <th>KOSTNADSPOST</th>
                  <th style={{ textAlign: "right" }}>SEK</th>
                </tr>
              </thead>
              <tbody>
                <DetailedRow
                  title="Bruttolön"
                  cost={salary.edeptoSalary.annualSalaryBrutto}
                />
                <DetailedRow
                  title="Arbetsgivaravgift"
                  cost={salary.edeptoSalary.annualArbAvg}
                />

                <DetailedRow
                  title={`Tjänstepension ${salaryVariables.occupationalPension} %`}
                  cost={salary.edeptoSalary.annualOccupationalPensionCost}
                >
                  <p style={{ fontSize: "0.8em", margin: "0" }}>
                    Beräknat som % av bruttolönen. Kostnaden inkluderar särskild
                    löneskatt på pensionskostnader (SLP) om 24,26 %.
                  </p>{" "}
                  <Slider
                    min={0}
                    max={20}
                    defaultValue={5}
                    step={1}
                    onChange={(d) => {
                      setSalaryVariables({
                        ...salaryVariables,
                        occupationalPension: d,
                      });
                    }}
                  />
                </DetailedRow>

                <DetailedRow
                  title="Sjukvårdsförsäkring"
                  cost={salaryVariables.privateHealthcarePlan}
                >
                  <p
                    style={{
                      fontSize: "0.8em",
                      margin: "0 0 0.3rem 0",
                      position: "relative",
                    }}
                  >
                    Snabb tillgång till rådgivning och specialistvård. Något vi
                    varmt rekommenderar!
                  </p>
                  <Switch
                    height={28}
                    width={56}
                    onColor={"#b5e0f9"}
                    onChange={(d) => {
                      setPrivateHealthcarePlanChecked(d);
                      if (d) {
                        setSalaryVariables({
                          ...salaryVariables,
                          privateHealthcarePlan: 6000,
                        });
                      } else {
                        setSalaryVariables({
                          ...salaryVariables,
                          privateHealthcarePlan: 0,
                        });
                      }
                    }}
                    checked={privateHealthcarePlanChecked}
                  />
                </DetailedRow>
                <DetailedRow
                  title="Friskvård"
                  cost={salaryVariables.healthcare}
                >
                  <p style={{ fontSize: "0.8em", margin: "0" }}>
                    Enklare aktiviteter med inslag av motion eller för
                    behandling som är avstressande eller motverkar ömhet och
                    stelhet, t.ex. gymkort och massage.
                  </p>
                  <Slider
                    min={0}
                    max={5000}
                    defaultValue={5000}
                    step={100}
                    onChange={(d) => {
                      setSalaryVariables({
                        ...salaryVariables,
                        healthcare: d,
                      });
                    }}
                  />
                </DetailedRow>

                <DetailedRow
                  title="Konferens"
                  cost={salaryVariables.conferences}
                >
                  <p style={{ fontSize: "0.8em", margin: "0" }}>
                    Flerdagarsaktivitet med kollegor i syfte att sprida
                    information, fördjupa sig inom något ämne, öka teamkänsla
                    och laganda.
                  </p>
                  <Slider
                    min={0}
                    max={30000}
                    defaultValue={15000}
                    step={1000}
                    onChange={(d) => {
                      setSalaryVariables({
                        ...salaryVariables,
                        conferences: d,
                      });
                    }}
                  />
                </DetailedRow>

                <DetailedRow
                  title="Representation"
                  cost={salaryVariables.representation}
                >
                  <p style={{ fontSize: "0.8em", margin: "0" }}>
                    Gemensamma sammankomster på hemmaplan med kollegor eller
                    affärsbekanta. Typiskt exempel är våra klassiska
                    informationsmöten.
                  </p>{" "}
                  <Slider
                    min={0}
                    max={18000}
                    defaultValue={9000}
                    step={1000}
                    onChange={(d) => {
                      setSalaryVariables({
                        ...salaryVariables,
                        representation: d,
                      });
                    }}
                  />
                </DetailedRow>
                <DetailedRow
                  title="Ansvarsförsäkring"
                  cost={liabilityInsurance}
                />

                <DetailedRow title="Övrigt" cost={administrativePrograms} />
              </tbody>
            </table>
          </div>
        </>
      </AnimateHeight>
    </>
  );
}

function formSEK(value) {
  if (value === 0) {
    return 0;
  }
  if (value) {
    return value.toLocaleString("se");
  }
}
export default EdeptoCalculatorDetails;

var administrativePrograms = 1200;
var liabilityInsurance = 2400;
