function Header() {
  return (
    <header id="header">
      <div className="logo">
        <span className="fas fa-shield-alt"></span>
      </div>
      <div className="content">
        <div className="inner">
          <h1>
            <img src="images/edepto-logo-trans.png" alt="logo" />
          </h1>
          <p >
            Ett IT-konsultbolag beläget i Göteborg. 90% av faktureringen till
            den anställde.
          </p>
        </div>
      </div>
      <nav>
        <ul>
          <li>
            <a href="#work">Vår idé</a>
          </li>
          <li>
            <a
              href="#salcalc"
              onClick={() => {
                fetch("/calcdummy");
              }}
            >
              Lönekalkylator
            </a>
          </li>
          <li>
            <a href="#about">Om oss</a>
          </li>
          <li>
            <a href="#contact">Kontakt</a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
