function DetailedLabel(props) {
  var onclickHandler = !props.expandable
    ? null
    : () => {
        props.setExpanded(!props.expanded);
      };

  return (
    <label style={{ margin: "0 0 0 0" }} onClick={onclickHandler}>
      {props.title}{" "}
      {props.expandable && !props.expanded && (
        <i className="fas fa-chevron-down"></i>
      )}
      {props.expandable && props.expanded && (
        <i className="fas fa-chevron-up"></i>
      )}
    </label>
  );
}

export default DetailedLabel;
