function OurIdea() {
  return (
    <article id="work">
      <h2 className="major">Vår idé</h2>
      <span className="image main">
        <img src="images/pic02.jpg" alt="" />
      </span>
      <p>
        Vi har skapat Edepto för att skaka om i konsultbranschen. Med vår
        90/10-modell ger vi våra konsulter 90 % av det de fakturerar, och
        friheten att välja hur de fördelar sina intäkter – oavsett om det
        handlar om lön, semester, tjänstepension eller andra förmåner.
      </p>

      <p>
        Vår affärsfilosofi innefattar att ge våra anställda makten att forma sin
        egen ekonomiska framtid och samtidigt skapa en sund balans mellan arbete
        och privatliv, vilket i sin tur bidrar till en förbättrad livskvalitet.
        Hos Edepto finns inga mellanchefer som tar en del av kakan. Istället har
        de effektiviserats bort till förmån för avancerade mjukvarulösningar som
        vi har utvecklat internt med stöd av artificiell intelligens. Våra
        grundare Hannes och Mikael ser till att allt löper som det ska och att
        de mänskliga aspekterna av vårt arbete, som de personliga mötena som vi
        värdesätter högt, aldrig ersätts av kall teknik. Vi håller fast vid att
        vissa saker är som bäst när de är just mänskliga.
      </p>

      <p>
        Vår vision är att tillhandahålla högkvalitativ IT-kompetens till de
        kunder där behovet är störst, samtidigt som vi upprätthåller en
        affärsmodell som premierar konsulten och uppmuntrar en hälsosammare
        arbetslivsbalans. Vi är Edepto – vi står för en personligt anpassad
        digitaliseringsprocess.
      </p>
      <p>Freedom of choice när den är som bäst, och det är bara början.</p>
    </article>
  );
}

export default OurIdea;
