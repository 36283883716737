/* eslint-disable */
import EdeptoCalculator from "./EdeptoCalculator";
import OurIdea from "./OurIdea";
import Header from "./Header";
import React, { useEffect } from "react";
import AboutUs from "./AboutUs";
import Contact from "./Contact";
import Policy from "./Policy";
import * as ext from "./main.js";
function Wrapper() {
  useEffect(() => {
    ext.renderSite(jQuery);
  }, []);
  return (
    <>
      <Header />
      <div id="main">
        <OurIdea />
        <EdeptoCalculator />
        <AboutUs />
        <Contact />
        <Policy />
      </div>
      <footer id="footer">
        <p className="copyright">© Edepto AB.</p>
        <p style={{ fontSize: "0.6rem", opacity: "0.75" }}>
          <a href="#riktlinjer_policydokument">Riktlinjer och policydokument</a>
        </p>
      </footer>
    </>
  );
}

export default Wrapper;
